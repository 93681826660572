import React, { useState, useEffect, useRef } from 'react';
import RecordRTC from 'recordrtc';
import { FFmpeg } from '@ffmpeg/ffmpeg';

function ScreenRecorder() {
  const [recorder, setRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingBlob, setRecordingBlob] = useState(null);
  const videoRef = useRef(null);
  const ffmpegRef = useRef(null);

  useEffect(() => {
    // Request permission for screen capture
    async function requestScreenCapture() {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: true // You can customize video capture options 
      });
      const newRecorder = new RecordRTC(stream, {
        type: 'video' // You could also record 'gif' or 'audio'
      });
      setRecorder(newRecorder);
    }

    requestScreenCapture();

    // Initialize ffmpeg instance
    ffmpegRef.current = new FFmpeg();
  }, []);

  const startRecording = () => {
    recorder.startRecording();
    setIsRecording(true);
  };

  const stopRecording = async () => {
    recorder.stopRecording(async () => {
      const blob = recorder.getBlob();
      setRecordingBlob(blob);
      setIsRecording(false);

      // Convert recorded blob to mp4 using ffmpeg.wasm
      if (blob) {
        await convertBlobToMP4(blob); // Now allowed within an async function
      }
    });
  };

  const convertBlobToMP4 = async (blob) => {
    const ffmpeg = ffmpegRef.current;
    await ffmpeg.load();

    const outputName = 'recording.mp4';  // Define output filename

    // Write recorded blob to temporary file
    await ffmpeg.write(outputName, blob);

    // Transcode to mp4 with h264 and aac codecs
    await ffmpeg.transcode(outputName, {
      audioCodec: 'aac',
      videoCodec: 'libx264',
    });

    // Read the converted mp4 blob
    const mp4Blob = await ffmpeg.read(outputName);

    // Clean up temporary file
    await ffmpeg.FS.deleteFile(outputName);

    // Update state with the converted mp4 blob for download
    setRecordingBlob(mp4Blob);
  };

  return (
    <div>
      <video ref={videoRef} autoPlay playsInline muted />

      <button disabled={isRecording} onClick={startRecording}>
        Start Recording
      </button>
      <button disabled={!isRecording} onClick={stopRecording}>
        Stop Recording
      </button>

      {recordingBlob && (
        <video 
          src={URL.createObjectURL(recordingBlob)} 
          controls 
        />
      )}
    </div>
  );
}

export default ScreenRecorder;
