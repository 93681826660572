import ScreenRecorder from './ScreenRecorder';
import './App.css'

function App() {

  return (
    <>
      <div>
        <ScreenRecorder />
      </div>
    </>
  )
}

export default App
